<template>
  <div id="contents" class="quality_control_ledger">
    <div class="contents_head">
      <h2>품질관리대장</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active"><a>품질관리대장</a></li>
        </ul>
      </div>
      <sanchae-product-verification-form></sanchae-product-verification-form>
    </div>
  </div>
</template>

<script>
import SanchaeProductVerificationForm from '@/views/forms/Quality/5/SanchaeProductVerificationForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    SanchaeProductVerificationForm,
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      // this.$store.commit('InitSanchaeProductVerificationPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
