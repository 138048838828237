<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>품질관리대장</h5>
        <div class="option_cont">
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
            <button class="btn_sub2" @click="reSearchData()">
              검색
            </button>
            <div class="input_search">
              <input
                type="text"
                placeholder="품명 검색"
                :value="searchText"
                @input="typing($event)"
              />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <button
              class="excel_btn"
              v-show="product_verifications.length > 0"
              @click="exportExcel"
            >
              엑셀로 출력
            </button>
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th rowspan="2">작업일</th>
              <th rowspan="2">품명</th>
              <th rowspan="2">판수</th>
              <th rowspan="2">전후진</th>
              <th rowspan="2">온도세팅</th>
              <th colspan="3">물양</th>
              <th colspan="3">날씨(온도)</th>
              <th colspan="3">날씨(습도)</th>
              <th rowspan="2">레일 속도</th>
              <th rowspan="2">불량(kg)</th>
              <th rowspan="2">총 박스 수</th>
              <th rowspan="2">판당 박스 수</th>
              <th rowspan="2">비고</th>
              <th rowspan="2">수정</th>
            </tr>
            <tr>
              <th>시작</th>
              <th>중간</th>
              <th>끝</th>
              <th>시작전</th>
              <th>중간</th>
              <th>종료후</th>
              <th>시작전</th>
              <th>중간</th>
              <th>종료후</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredProductVerifications" :key="item.rows">
              <td>{{ item.input_date_temp }}</td>
              <td>{{ findInfoFromId(products, item.product_id_temp).name }}</td>
              <td>{{ $makeComma(item.quantity_temp) }}</td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.jeonhujin || 0)"
                  @input="
                    $inputNumber($event, item, 'jeonhujin', 2000, -2000, true)
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.temp_setting || 0)"
                  @input="$inputNumber($event, item, 'temp_setting')"
                />
              </td>

              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.water_start || 0)"
                  @input="$inputNumber($event, item, 'water_start')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.water_middle || 0)"
                  @input="$inputNumber($event, item, 'water_middle')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.water_end || 0)"
                  @input="$inputNumber($event, item, 'water_end')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.temp_start || 0)"
                  @input="
                    $inputNumber($event, item, 'temp_start', 1000, -1000, true)
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.temp_middle || 0)"
                  @input="
                    $inputNumber($event, item, 'temp_middle', 1000, -1000, true)
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.temp_end || 0)"
                  @input="
                    $inputNumber($event, item, 'temp_end', 1000, -1000, true)
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.humi_start || 0)"
                  @input="$inputNumber($event, item, 'humi_start')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.humi_middle || 0)"
                  @input="$inputNumber($event, item, 'humi_middle')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.humi_end || 0)"
                  @input="$inputNumber($event, item, 'humi_end')"
                />
              </td>
              <td>
                <input
                  type="text"
                  :value="$makeComma(item.rail_speed_start || 0)"
                  @input="$inputNumber($event, item, 'rail_speed_start')"
                />~<input
                  type="text"
                  :value="$makeComma(item.rail_speed_end || 0)"
                  @input="$inputNumber($event, item, 'rail_speed_end')"
                />
              </td>
              <td>
                <input
                  type="text"
                  inputmode="double"
                  :value="$makeComma(item.fail_quantity || 0)"
                  @input="$inputNumber($event, item, 'fail_quantity')"
                />
              </td>
              <td>
                {{ $makeComma(item.box_quantity) }}
              </td>
              <td>
                {{
                  $makeComma(
                    $makeNumberFixed2(
                      $decimalDiv(item.box_quantity, item.quantity_temp),
                    ),
                  )
                }}
              </td>
              <td>
                <input
                  class="input_comment"
                  type="text"
                  :value="item.comment"
                  @input="$event => (item.comment = $event.target.value)"
                />
              </td>
              <td>
                <button class="btn_tbl" @click="submitRow(item)">수정</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import MODAL_MIXIN from '@/mixins/modal';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN, SPINNER_MIXIN, MODAL_MIXIN],
  data() {
    return {
      searchText: '',
      lock_btn: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedIndex: 'getSelectedIndexFromSanchaeProductVerification',
      managementData: 'getManagementDataFromSanchaeProductVerification',
      product_verifications:
        'getProductVerificationFromSanchaeProductVerification',
      products: 'getProduct',
      box_units: 'getBoxs',
      start_date: 'getStartDateFromSanchaeProductVerification',
      end_date: 'getEndDateFromSanchaeProductVerification',
      plc_sensor_logs: 'getSanchaeSensorLogFromSanchaeProductverification',
    }),
    filteredProductVerifications() {
      const Hangul = require('hangul-js');
      return this.product_verifications
        .filter(
          x =>
            this.findInfoFromId(this.products, x.product_id_temp).name.includes(
              this.searchText,
            ) ||
            Hangul.d(
              this.findInfoFromId(this.products, x.product_id_temp).name,
              true,
            )
              .map(x => x[0])
              .join('')
              .includes(this.searchText),
        )
        .sort(
          (a, b) => new Date(b.input_date_temp) - new Date(a.input_date_temp),
        );
    },
  },
  methods: {
    async reSearchData() {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_V_PRODUCT_VERIFICATION_DAY',
        {
          start_date: this.start_date,
          end_date: this.end_date,
        },
        '품질관리대장',
      );
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_SANCHAE_TH_LOG',
        { start: this.start_date, end: this.end_date },
        '배합실 온습도',
      );
      const payload = this.product_verifications.map(x => {
        const filteredDayTemps = this.lodash
          .clonedeep(this.plc_sensor_logs)
          .filter(
            y =>
              y.plc_sensor_id == 15 &&
              yyyymmdd(new Date(y.create_time)) == x.input_date_temp,
          );
        const filteredDayHumis = this.lodash
          .clonedeep(this.plc_sensor_logs)
          .filter(
            y =>
              y.plc_sensor_id == 16 &&
              yyyymmdd(new Date(y.create_time)) == x.input_date_temp,
          );
        const tempStart = filteredDayTemps.find(
          y => y.create_time.substr(11, 5) == '08:30',
        );
        const tempMiddle = filteredDayTemps.find(
          y => y.create_time.substr(11, 5) == '12:00',
        );
        const tempEnd = filteredDayTemps.find(
          y => y.create_time.substr(11, 5) == '16:00',
        );
        const humiStart = filteredDayHumis.find(
          y => y.create_time.substr(11, 5) == '08:30',
        );
        const humiMiddle = filteredDayHumis.find(
          y => y.create_time.substr(11, 5) == '12:00',
        );
        const humiEnd = filteredDayHumis.find(
          y => y.create_time.substr(11, 5) == '16:00',
        );
        if (x.temp_start == 0) {
          x.temp_start = tempStart != undefined ? tempStart.value : 0;
          x.temp_middle = tempMiddle != undefined ? tempMiddle.value : 0;
          x.temp_end = tempEnd != undefined ? tempEnd.value : 0;
          x.humi_start = humiStart != undefined ? humiStart.value : 0;
          x.humi_middle = humiMiddle != undefined ? humiMiddle.value : 0;
          x.humi_end = humiEnd != undefined ? humiEnd.value : 0;
        }

        return x;
      });
      this.$store.commit(
        'setProductVerificationToSanchaeProductVerification',
        payload,
      );
    },
    typing(e) {
      this.searchText = e.target.value;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit(
            'setStartDateToSanchaeProductVerification',
            e.target.value,
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToSanchaeProductVerification',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit(
            'setEndDateToSanchaeProductVerification',
            e.target.value,
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToSanchaeProductVerification',
          yyyymmdd(new Date()),
        );
      }
    },
    async submitRow(item) {
      if (this.lock_btn) {
        return;
      } else {
        this.lock_btn = true;
      }
      this.showSpinner();
      const payload = this.lodash.clonedeep(item);
      payload.input_date = payload.input_date_temp;
      payload.product_id = payload.product_id_temp;
      payload.jeonhujin = this.$makeNumber(payload.jeonhujin);
      payload.temp_setting = this.$makeNumber(payload.temp_setting);
      payload.water_start = this.$makeNumber(payload.water_start);
      payload.water_middle = this.$makeNumber(payload.water_middle);
      payload.water_end = this.$makeNumber(payload.water_end);
      payload.temp_start = this.$makeNumber(payload.temp_start);
      payload.temp_middle = this.$makeNumber(payload.temp_middle);
      payload.temp_end = this.$makeNumber(payload.temp_end);
      payload.humi_start = this.$makeNumber(payload.humi_start);
      payload.humi_middle = this.$makeNumber(payload.humi_middle);
      payload.humi_end = this.$makeNumber(payload.humi_end);
      payload.rail_speed_start = this.$makeNumber(payload.rail_speed_start);
      payload.rail_speed_end = this.$makeNumber(payload.rail_speed_end);
      payload.fail_quantity = this.$makeNumber(payload.fail_quantity);
      payload.lot_id = payload.lot_id_temp;
      const INSERT_OR_UPDATE =
        payload.id != undefined && payload.id != null ? 'UPDATE' : 'INSERT';
      this.$store
        .dispatch(`${INSERT_OR_UPDATE}_SANCHAE_PRODUCT_VERIFICATION`, payload)
        .then(() => {
          this.openOneButtonModal('수정 완료', '수정되었습니다.');
        })
        .catch(error => {
          console.log(error);
          if (
            error['data'] != undefined &&
            error.data.detail.includes('pkey')
          ) {
            this.openOneButtonModal(
              '신규 등록 실패',
              '이미 해당 생산공정의 입력 데이터가 존재합니다.',
            );
          } else {
            this.openOneButtonModal(
              '등록 실패',
              '품질관리대장 수정 중 오류 발생',
            );
          }
        })
        .finally(() => {
          this.hideSpinner();
          this.lock_btn = false;
        });
    },
    exportExcel() {
      let productVerificationList = [];
      let date = this.start_date + ' ~ ' + this.end_date;
      let searchProductName = '';

      if (this.searchText == '') {
        searchProductName = '전체';
      } else {
        let searchData = new Set(
          this.filteredProductVerifications.map(
            x => this.findInfoFromId(this.products, x.product_id_temp).name,
          ),
        );
        searchProductName = [...searchData].join(', ');
      }

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
        // =========== 테이블 헤더 ==============
        { s: { r: 6, c: 0 }, e: { r: 7, c: 0 } },
        { s: { r: 6, c: 1 }, e: { r: 7, c: 1 } },
        { s: { r: 6, c: 2 }, e: { r: 7, c: 2 } },
        { s: { r: 6, c: 3 }, e: { r: 7, c: 3 } },
        { s: { r: 6, c: 4 }, e: { r: 7, c: 4 } },
        { s: { r: 6, c: 5 }, e: { r: 6, c: 7 } },
        { s: { r: 6, c: 8 }, e: { r: 6, c: 10 } },
        { s: { r: 6, c: 11 }, e: { r: 6, c: 13 } },
        { s: { r: 6, c: 14 }, e: { r: 7, c: 14 } },
        { s: { r: 6, c: 15 }, e: { r: 7, c: 15 } },
        { s: { r: 6, c: 16 }, e: { r: 7, c: 16 } },
        { s: { r: 6, c: 17 }, e: { r: 7, c: 17 } },
        { s: { r: 6, c: 18 }, e: { r: 7, c: 18 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        ['조회 품명', searchProductName],
        [],
        [],
      ];

      productVerificationList = productVerificationList.concat(standardInfo);

      productVerificationList.push([
        '작업일',
        '품명',
        '판수',
        '전후진',
        '온도세팅',
        '물양',
        '',
        '',
        '날씨(온도)',
        '',
        '',
        '날씨(습도)',
        '',
        '',
        '레일 속도',
        '불량(kg)',
        '총 박스 수',
        '판당 박스 수',
        '비고',
      ]);
      productVerificationList.push([
        '',
        '',
        '',
        '',
        '',
        '시작',
        '중간',
        '끝', // 물양
        '시작전',
        '중간',
        '종료후', // 날씨 - 온도
        '시작전',
        '중간',
        '종료후', // 날씨 - 습도
        '',
        '',
        '',
        '',
        '',
        '',
      ]);

      this.filteredProductVerifications.forEach(el => {
        let railStart = this.$makeComma(el.rail_speed_start || 0);
        let railEnd = this.$makeComma(el.rail_speed_end || 0);

        productVerificationList.push([
          el.input_date_temp,
          this.findInfoFromId(this.products, el.product_id_temp).name,
          this.$makeComma(el.quantity_temp),
          this.$makeComma(el.jeonhujin || 0),
          this.$makeComma(el.temp_setting || 0),

          this.$makeComma(el.water_start || 0),
          this.$makeComma(el.water_middle || 0),
          this.$makeComma(el.water_end || 0),

          this.$makeComma(el.temp_start || 0),
          this.$makeComma(el.temp_middle || 0),
          this.$makeComma(el.temp_end || 0),

          this.$makeComma(el.humi_start),
          this.$makeComma(el.humi_middle),
          this.$makeComma(el.humi_end),

          railStart + ' ~ ' + railEnd,

          this.$makeComma(el.fail_quantity || 0),

          this.$makeComma(el.box_quantity),

          this.$makeComma(
            this.$makeNumberFixed2(
              this.$decimalDiv(el.box_quantity, el.quantity_temp),
            ),
          ),
          el.comment,
        ]);
      });
      let wb = XLSX.utils.book_new();

      let excelData = XLSX.utils.json_to_sheet(productVerificationList, {
        skipHeader: true,
      });

      excelData['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, excelData, '품질관리대장');

      let title = '품질관리대장(' + date + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();

      this.$store.commit(
        'setEndDateToSanchaeProductVerification',
        yyyymmdd(date),
      );
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit(
        'setStartDateToSanchaeProductVerification',
        yyyymmdd(date2),
      );
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT');
    }
    if (this.box_units.length < 1) {
      await this.FETCH('FETCH_BOX');
    }
    await this.reSearchData();
  },
};
</script>
